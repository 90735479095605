<template>
  <div class="content">
    <b-container>
      <b-row align-v="center">
        <b-col cols="12">
          <div class="wrap_logo_default">
            <img src="/global_assets/images/logo_purple.png" />
          </div>
          <b-card>
            <b-row>
              <b-col md="8">
                <div class="project_frm_wrap">
                  <h3>Create a New Project</h3>
                  <p>Enter project name, keyword or hashtag to monitor.</p>
                  <b-form @submit.prevent="doCreateProject">
                    <b-row>
                      <b-col md="8">
                        <b-form-group
                          label-for="projectName"
                          class="form-group-feedback form-group-feedback-left"
                        >
                          <template #label>Project Name<small class="mandatory_txt">*</small></template>
                          <div class="position-relative">
                            <b-form-input placeholder="e.g. Rancang Mebel" id="projectName" v-model="projName" />
                            <div class="form-control-feedback form-control-feedback-lg">
                              <i class="icon-file-text2"></i>
                            </div>
                          </div>
                        </b-form-group>
                      </b-col>
                      <b-col md="5">
                        <b-form-group label-for="projectKeyword">
                          <template #label>Keyword<small class="mandatory_txt">*</small></template>
                          <b-input-group v-for="(v,k) in projKeywords" :key="k">
                            <b-form-input id="projectKeyword" v-model="v.word" placeholder="e.g. Custom Furniture" />
                            <template #append>
                              <b-button
                                @click="operateKeyword('add')"
                                variant="light"
                                v-if="k == (projKeywords.length - 1)"
                              ><i class="icon-plus2 mr-0"></i></b-button>
                              <b-button
                                @click="operateKeyword('remove', k)"
                                v-else
                                variant="transparent"
                                class="alpha-pink text-pink-800"
                              ><i class="icon-minus2 mr-0"></i></b-button>
                            </template>
                          </b-input-group>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-form-group label-for="projectHashtags">
                      <template #label>Hashtag <small class="text-muted">(optional)</small></template>
                      <b-form-tags tag-class="bg-indigo-400" id="projectHashtags" v-model="projHashtags" />
                    </b-form-group>
                    <b-button class="bg-indigo-400" type="submit" variant="transparent">Create Project</b-button>
                  </b-form>
                </div>
              </b-col>
              <b-col md="4">
                <div class="graphic_info">
                  <img class="img-responsive" src="/global_assets/images/graphic1.jpg" />
                  <h4>Information</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                    labore et dolore magna aliqua
                  </p>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <b-modal
      centered
      id="addProjectProcess"
      hide-footer
      hide-header
    >
      <div class="wrap_info_loader pb-2">
        <h3>Processing Data... </h3>
        <i class="icon-spinner2 spinner"></i>

        <p><span id="counter">{{ counter }}</span> second(s).</p>
      </div>
    </b-modal>
  </div>
</template>
<script>
const _ = require('lodash')

export default {
  data() {
    return {
      counter: 0,
      projectAdded: false,
      projName: '',
      projKeywords: [
        { word: '' }
      ],
      projHashtags: [],
    }
  },
  methods: {
    doCreateProject() {
      this.counter = 10
      this.$bvModal.show('addProjectProcess')
    },
    operateKeyword(mode, index) {
      if (mode == 'add') {
        this.projKeywords.push({ word: '' })
      }
      else {
        this.projKeywords.splice(index, 1)
      }
    }
  },
  watch: {
    counter: {
      handler(val) {
        if (val > 0) {
          this.projectAdded = true
          setTimeout(() => {
            this.counter--
          }, 1000)
        }else if (val == 0 && this.projectAdded) {
          this.$bvModal.hide('addProjectProcess')
          this.$router.push({ name: 'ProjectDashboard', params: { project: _.kebabCase(this.projName) } })
        }
      },
      immediate: true,
    }
  }
}
</script>